/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Piercing uszu - przekłuwanie uszu w Olsztynie | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/piercing-uszu/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Piercing = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Piercing uszu<br />w Gabinecie Kosmetycznym "Gabinet&nbsp;Elżbieta"</h1>

        <p>W naszym salonie kosmetycznym dbamy o&nbsp;bezpieczeństwo i&nbsp;komfort naszych klientów podczas każdej procedury. Dlatego stosujemy wyłącznie bezpieczną, całkowicie sterylną metodę przekłucia uszu, korzystając z&nbsp;zaawansowanego urządzenia Studex. Jest to firma znana z&nbsp;produkcji wysokiej jakości kolczyków, których bezpieczeństwo i&nbsp;komfort noszenia są priorytetem.</p>

        <h2>Kolczyki Studex - gwarancja bezpieczeństwa</h2>

        <p>Kolczyki Studex dzięki zastosowaniu stali chirurgicznej, są bezpieczne dla skóry i&nbsp;nie powodują uczuleń, podrażnień ani stanów zapalnych. Co więcej, są wolne od niklu i&nbsp;ołowiu, co sprawia, że są odpowiednie nawet dla osób z&nbsp;wrażliwą skórą.</p>

        <p>W "Gabinecie Elżbieta" oferujemy szeroki wybór kolczyków Studex, w&nbsp;tym srebrne, złote oraz z&nbsp;różnymi kolorami oczek, aby każdy klient mógł znaleźć coś odpowiedniego dla siebie.</p>

        <h2>Proces przekłucia uszu</h2>

        <p>Przebieg zabiegu jest prosty i&nbsp;bezpieczny, a&nbsp;samo przekłucie zajmuje zaledwie chwilę. Oto kroki, które wykonujemy podczas procedury:</p>

        <ol>
          <li>Dezynfekcja płatków uszu.</li>
          <li>Zaznaczenie flamastrem chirurgicznym miejsca wkłucia kolczyków, zapewniając symetryczność.</li>
          <li>Wprowadzenie kolczyka do pistoletu.</li>
          <li>Przekłucie ustalonego miejsca.</li>
        </ol>

        <p>Dzięki zastosowaniu urządzenia Studex oraz starannej techniki wykonania zabiegu, możesz być pewien, że proces będzie przebiegał sprawnie i&nbsp;bezpiecznie.</p>

        <h2>Pielęgnacja po zabiegu</h2>

        <p>Po przekłuciu uszu ważna jest odpowiednia pielęgnacja, aby zapewnić szybkie gojenie się tkanek oraz uniknąć infekcji. Kolczyki Studex powinny pozostać w&nbsp;uszach przez około 6&nbsp;tygodni do dwóch miesięcy, po czym można je wymienić na inne kolczyki o&nbsp;prostym drucie wykonane ze złota, srebra lub stali chirurgicznej.</p>

        <p>Przez pierwsze 6&nbsp;miesięcy po przekłuciu, zaleca się noszenie kolczyków nieprzerwanie. W&nbsp;okresie gojenia (około 2&nbsp;tygodnie), należy unikać korzystania z&nbsp;basenu i&nbsp;solarium, aby zapobiec ewentualnym infekcjom.</p>

        <figure style={{
          margin: '75px 0 100px',
          textAlign: 'center',
        }}
        >
          <GatsbyImage
            image={getImage(data.ear_piercing)}
            alt="Przekłuwanie uszu za pomocą urządzenia Studex u małej dziewczynki"
            loading="eager"
          />
        </figure>

        <h2>Przeciwwskazania</h2>

        <p>Chociaż przekłucie uszu jest stosunkowo bezpiecznym zabiegiem, istnieją pewne przeciwwskazania, które należy wziąć pod uwagę:</p>

        <ul>
          <li>stany zapalne ucha</li>
          <li>gorączka</li>
          <li>ciąża</li>
          <li>problemy z krzepnięciem krwi</li>
        </ul>

        <p>Jeśli masz jakiekolwiek wątpliwości lub pytania dotyczące przekłucia uszu, zawsze możesz skonsultować się z naszym doświadczonym personelem, który z przyjemnością udzieli Ci wszelkich niezbędnych informacji.</p>

        <p>Zapraszamy do "Gabinetu Elżbieta", gdzie profesjonalizm, bezpieczeństwo i indywidualne podejście do klienta są naszymi priorytetami. Zarezerwuj już dziś swoją wizytę i ciesz się pięknymi kolczykami bez obaw o zdrowie i komfort!</p>

        <p>Aktualne ceny sprawdzisz w naszym <Link to="/cennik/">Cenniku</Link>.</p>

        {/* <h1>Przekłuwanie uszu</h1>

        <p>W naszym salonie kosmetycznym stosujemy bezpieczną, całkowicie sterylną metodę przekłucia uszu, za pomocą urządzenia Studex. Kolczyki Studex są produkowane w&nbsp;Los Angeles w Kalifornii. Kolczyki te nie powodują uczuleń, podrażnień stanów zapalnych. Są&nbsp;wykonane ze stali chirurgicznej. Nie posiadają niklu i&nbsp;ołowiu.</p>

        <p>Posiadamy w ofercie kolczyki srebrne, złote, z&nbsp;różnymi kolarami oczek. Sam zabieg zajmuje tylko chwilę.</p>

        <h2>Przebieg zabiegu</h2>

        <ul>
          <li>dezynfekcja płatków uszu</li>
          <li>zaznaczamy flamastrem chirurgicznym miejsce wkłucia kolczyków (ważne jest, aby były to miejsca symetryczne)</li>
          <li>kolczyk wprowadzamy do pistoletu</li>
          <li>następnie przekłuwamy dane miejsce</li>
        </ul>

        <h2>Pielęgnacja</h2>

        <p>Po przekłuciu uszu, kolczyki zostają w uszach na około 6&nbsp;tygodni, do dwóch miesięcy. Po tym czasie możemy wyjąć kolczyki i&nbsp;założyć inne o&nbsp;prostym drucie. Kolczyki te powinny być wykonane ze złota, srebra lub ze stali chirurgicznej.</p>

        <p>Kolczyki należy nosić nieprzerwanie przez około 6&nbsp;miesięcy. W&nbsp;okresie gojenia (około 2&nbsp;tygodnie) nie korzystamy z&nbsp;basenu i solarium.</p>

        <h2>Przeciwwskazania</h2>

        <ul>
          <li>stany zapalne ucha</li>
          <li>gorączka</li>
          <li>ciąża</li>
          <li>problemy z krzepnięciem krwi</li>
        </ul>

        <h2>Cena od 49 zł.</h2> */}
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
    ear_piercing: file(relativePath: {regex: "/offer\/piercing\/przekluwanie-uszu.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 500
        )
      }
    }
  }
`;

export default Piercing;
